import {
  addBreadcrumb,
  Breadcrumb,
  captureException,
  captureMessage,
} from '@sentry/nextjs';

import { LogOptions } from '@lib/utils';

/**
 * Send to sentry and our logs if on server
 * @param breadcrumb Sentry Breadcrumb
 */
export const logBreadcrumb = (breadcrumb: Breadcrumb, sessionId?: string) => {
  addBreadcrumb(breadcrumb); // send to sentry
  console.log(
    `${breadcrumb.message}\n${JSON.stringify(breadcrumb.data)}\nSession ID: ${sessionId}`
  ); // send to our logs
};

/**
 * Used to send errors and messages to our internal logs and sentry
 * @param message either a string or an error
 */
export const log = ({ error, location, sessionId }: LogOptions) => {
  if (error instanceof Error) {
    captureException(error); // send to sentry
    console.error(
      `Encountered error, triggered by: ${location}. error: ${error.message}\nSession ID: ${sessionId}`
    ); // send to our logs
  } else {
    captureMessage(String(error)); // send to sentry
    console.log(`${error} \nSession ID: ${sessionId}`); // send to our logs
  }
};
