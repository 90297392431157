import { useSWRConfig } from 'swr';

import { useCustomer } from '@hooks/customer/useCustomer';
import { isErrorResponse } from '@interfaces/BulkAPI';
import {
  AddItemGiftCertificate,
  AddItemLineItem,
  Cart,
} from '@interfaces/ICart';
import { SWR_KEYS } from '@lib/constants';
import { log } from '@lib/utils';
import { AddCartItemAPIResponse } from 'pages/api/cart/add-item';

export function useAddItem({ include }: { include?: string[] }) {
  const { mutate } = useSWRConfig();
  const { customer } = useCustomer(); // TODO: refactor to have customer in context

  return async ({
    lineItem,
    giftCertificate,
  }: {
    lineItem?: AddItemLineItem;
    giftCertificate?: AddItemGiftCertificate;
  }): Promise<{
    data: Cart | null;
    error: string | null;
  }> => {
    const response = await fetch('/api/cart/add-item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        item: {
          lineItem,
          giftCertificate,
        },
        include,
      }),
    });

    try {
      const res: AddCartItemAPIResponse = await response.json();

      // Can we be more useful with error message
      if (isErrorResponse(res)) {
        let errorMessage = res.error.message.join(' ');
        if (errorMessage.includes('sufficient stock')) {
          errorMessage = 'There is not currently sufficient stock of that item';
        } else if (errorMessage.includes('No variants')) {
          errorMessage = 'That item is not currently available';
        }

        return {
          data: null,
          error: errorMessage,
        };
      }

      if (res.data && res.data.customer_id === 0 && customer) {
        // add customer_id to cart
        await fetch('/api/cart/add-customer-id', {
          method: 'POST',
          body: JSON.stringify({
            cartId: res.data.id,
            customerId: customer?.entityId,
          }),
        });
      }

      mutate(SWR_KEYS.cart);

      return {
        data: res.data,
        error: null,
      };
    } catch (err) {
      log({ error: err, location: 'useAddItem' });
      return {
        data: null,
        error: 'Unexpected remote server error',
      };
    }
  };
}
