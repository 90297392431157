import { Breadcrumb } from '@sentry/nextjs';

import {
  logBreadcrumb as clientBreadcrumb,
  log as clientLog,
} from './logs/client';
import {
  logBreadcrumb as serverBreadcrumb,
  log as serverLog,
} from './logs/server';
import { passwordRegexAnti } from './regex';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const validatePassword = (password: string) => {
  let message = '';
  if (password.length === 0) return message;

  // Check length
  if (password.length < 12) {
    message += `Your password is too short (must be at least 12 characters and you entered ${password.length})`;
  }

  // Check has number
  if (!/\d/.test(password)) {
    message += `${
      message.length > 0 ? '. ' : ''
    }Your password must contain at least one number`;
  }

  // Check has lowercase
  if (!/[a-z]/.test(password)) {
    message += `${
      message.length > 0 ? '. ' : ''
    }Your password must contain at least one lowercase letter`;
  }

  // Check has uppercase
  if (!/[A-Z]/.test(password)) {
    message += `${
      message.length > 0 ? '. ' : ''
    }Your password must contain at least one uppercase letter`;
  }

  // Check what they did for invalid characters
  const invalidChars = new Set(
    [...password.trim().matchAll(passwordRegexAnti)].map((a) => a[0])
  );
  if (invalidChars.size > 0) {
    message += `${
      message.length > 0 ? '. ' : ''
    }You used the following invalid characters: ${[...invalidChars].join(' ')}`;
  }

  return message;
};

/**
 * Send to sentry and our logs if on server
 * @param breadcrumb Sentry Breadcrumb
 */
export const logBreadcrumb = (breadcrumb: Breadcrumb, sessionId?: string) => {
  if (typeof window === 'undefined') {
    serverBreadcrumb(breadcrumb, sessionId);
  } else {
    clientBreadcrumb(breadcrumb);
  }
};

export interface LogOptions {
  error: unknown | string | Error;
  location?: string;
  sessionId?: string;
}
/**
 * Used to send errors and messages to our internal logs and sentry
 * @param error either a string or an Error
 * @param location if not obvious from error, where it was triggered
 * @param sessionId for server logs will need to be passed from cookies, client gets it itself
 */
export const log = ({ error, location, sessionId }: LogOptions) => {
  const isServer = typeof window === 'undefined';

  if (isServer) {
    serverLog({ error, location, sessionId });
  } else {
    clientLog({ error, location });
  }
};

/**
 * Generate a random number of a given length
 * @param len length of number you want
 * @returns random number of length given
 */
export const generateRandomNumber = (len: number) => {
  const add = 1;
  let max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros

  let out;
  if (len > max) {
    out = parseInt(
      generateRandomNumber(max).toString() +
        generateRandomNumber(len - max).toString()
    );
  } else {
    // Make a random number of the given length + 1 digit long so we don't have leading 0 that gets removed as a number
    // Math.random() returns a number between 0 (inclusive) and 1 (exclusive) i.e. [0,1)
    max = Math.pow(10, len + add);
    const min = Math.pow(10, len);
    const number = Math.floor(Math.random() * (max - min + 1)) + min; // -min + 1 stuff is to account for exclusive upper bound

    out = ('' + number).slice(-6); // take off that extra digit at the start we inserted
  }

  return out;
};
