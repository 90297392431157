import * as Tooltip from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';

export function TooltipWrapper({
  children,
  text,
}: {
  children: ReactNode;
  text: string;
}) {
  return text.length === 0 ? <>{children}</> : (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          className="
            z-3 select-none bg-white
            px-4 
            py-2.5 shadow-tooltip
            will-change-[transform,opacity] 
            data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade 
            data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade 
            dark:bg-grey-darkest 
            dark:shadow-tooltipDark"
          sideOffset={5}
        >
          {text}
          <Tooltip.Arrow className="fill-white dark:fill-grey-darkest" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}
