import React, { ReactElement, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ErrorCard } from '@components/Alerts';
import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Button, ButtonType } from '@components/Button';
import { Input } from '@components/FormComponents';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useLogin } from '@hooks/customer/useLogin';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { getErrorMessage } from '@lib/utils';

/**
 *  To enable the ability to put links in the messages,
 *  rather than return the server message,
 * we have some custom ones based on the code
 */
const ERROR_CODES = {
  4011: (
    <div>
      To ensure security of our customers' accounts, we require you to update
      your password and have emailed you with instructions. Please check your
      spam/junk mail folder or{' '}
      <TextLink href="/contact-us">
        reach out to our Customer Service team
      </TextLink>
      .
    </div>
  ),
  4012: (
    <div>
      To ensure security of our customers' accounts, we require you to update
      your password. We were unable to email you with instructions, so please
      follow the{' '}
      <TextLink href="/forgot-password">forgot your password</TextLink> process,
      or{' '}
      <TextLink href="/contact-us">
        reach out to our Customer Service team
      </TextLink>
      .
    </div>
  ),
  4013: (
    <div>
      You have been locked out of your account due to too many failed login
      attempts. Please reset your password to regain access via the{' '}
      <TextLink href="/forgot-password">forgot your password</TextLink> process.
      If you have any difficulties,{' '}
      <TextLink href="/contact-us">
        reach out to our Customer Service team
      </TextLink>
      .
    </div>
  ),
  4014: (
    <div>
      Your email address or password is incorrect. Please try again. If you've
      forgotten your sign in details, please reset your password via the{' '}
      <TextLink href="/forgot-password">forgot your password</TextLink> process.
    </div>
  ),
};

export function LoginFormError({ message }: { message: string }): ReactElement {
  return <ErrorCard message={message} title="" testid="error-card" />;
}

/**
 * Login form
 * @param handleSubmit function to submit the form
 * @param disabled to disable login button
 * @param title title of the form
 */
export function LoginForm({ onLogin }: { onLogin?: () => void }): ReactElement {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const login = useLogin();
  const rhfMethods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleSubmit = async (e: { email: string; password: string }) => {
    try {
      setError(false);
      setDisabled(true);
      const customer = await login({
        email: e.email,
        password: e.password,
      });
      if (isErrorResponse(customer)) {
        const code = customer.error.code;
        if (code in ERROR_CODES) {
          throw new Error(customer.error.code.toString());
        }
        throw new Error(customer.error.message.join('. '));
      }
      if (onLogin) onLogin();
      setDisabled(false);
    } catch (err) {
      setError(true);
      const code = parseInt(getErrorMessage(err));
      if (isNaN(code)) {
        setErrorMessage(getErrorMessage(err));
      } else {
        setErrorMessage(ERROR_CODES[code]);
      }
      setDisabled(false);
    }
  };

  // workaround to fix focus on inputs that are used on Pure React Carousel
  // https://github.com/express-labs/pure-react-carousel/discussions/367
  const handleInputClick = (e) => {
    e.target.focus();
  };

  return (
    <FormProvider {...rhfMethods}>
      <ErrorAlert show={error} setShow={setError}>
        <Paragraph>{errorMessage}</Paragraph>
      </ErrorAlert>
      <form
        data-testid="login-form"
        autoComplete="off"
        className="flex flex-col gap-4"
        onSubmit={rhfMethods.handleSubmit(handleSubmit)}
      >
        <Input
          label="Email Address"
          name="email"
          type="email"
          placeholder="email@domain.com"
          onClick={(e) => handleInputClick(e)}
        />
        <Input
          label="Password"
          name="password"
          type="password"
          onClick={(e) => handleInputClick(e)}
        />

        <div className="actions flex items-center gap-4">
          <Button
            type={ButtonType.submit}
            disabled={disabled}
            buttonStyle="primary"
            testid="login-button"
            className="mr-5"
          >
            Sign In
          </Button>
          <TextLink href="/forgot-password" data-testid="forgot-password-link">
            Forgot your password?
          </TextLink>
        </div>
      </form>
    </FormProvider>
  );
}
