import {
  addBreadcrumb,
  Breadcrumb,
  captureException,
  captureMessage,
} from '@sentry/nextjs';
import Cookies from 'js-cookie';

import { COOKIES } from '@lib/constants';
import { LogOptions } from '@lib/utils';

const LOCAL_LOG = true;

/**
 * Send to sentry and our logs if on server
 * @param breadcrumb Sentry Breadcrumb
 */
export const logBreadcrumb = (breadcrumb: Breadcrumb) => {
  addBreadcrumb(breadcrumb); // send to sentry
  if (process.env.NEXT_PUBLIC_SERVER_URL?.includes('localhost') && LOCAL_LOG)
    console.log(breadcrumb.message, breadcrumb); // log it if localhost
};

/**
 * Used to send errors and messages to our internal logs and sentry
 * @param message either a string or an error
 */
export const log = ({ error, location }: LogOptions) => {
  // Add a breadcrumb to sentry of the session id
  addBreadcrumb({
    message: 'Location and Session',
    data: {
      location,
      session: Cookies.get(COOKIES.session),
    },
  });

  // Actual error type
  if (error instanceof Error) {
    captureException(error); // send to sentry
    // print to console if localhost
    if (process.env.NEXT_PUBLIC_SERVER_URL?.includes('localhost'))
      console.error(
        `Encountered error, triggered by: ${location}. error: ${error.message}`
      );
  } else {
    // Just a message
    captureMessage(String(error)); // send to sentry
    if (process.env.NEXT_PUBLIC_SERVER_URL?.includes('localhost') && LOCAL_LOG)
      console.log(error); // send to our logs
  }
};
