/**
 * Renders a link that looks like a button
 */
import React, { ReactElement, useState } from 'react';
import { useSWRConfig } from 'swr';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useLogout } from '@hooks/customer/useLogout';
import { SWR_KEYS } from '@lib/constants';

import { Button, ButtonProps } from './Button';

export const LogoutButton = React.forwardRef<HTMLElement, ButtonProps>(
  ({ ...props }, forwardedRef): ReactElement => {
    const logout = useLogout();
    const { mutate } = useSWRConfig();
    const [error, setError] = useState(false);

    const handleLogout = async () => {
      const succeeded = await logout();
      if (succeeded) {
        mutate(SWR_KEYS.customer);
        mutate(SWR_KEYS.alerts);
        window.location.assign(window.location.origin);
      } else {
        setError(true);
      }
    };

    return (
      <>
        <Button ref={forwardedRef} {...props} onClick={handleLogout} />
        <ErrorAlert show={error} setShow={setError}>
          <Paragraph>
            There was an issue logging you out. Please try again
          </Paragraph>
        </ErrorAlert>
      </>
    );
  }
);

LogoutButton.displayName = 'LogoutButton';
